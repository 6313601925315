let items = [
  {
    name: "SECTION ONE",
    subs: [
      {
        name: "Calendrier",
        url: "/agenda",
        icon: "far fa-calendar-alt",
        matches_routes: ["agenda"]
      },
      {
        name: "Configurer calendrier",
        url: "/agenda/settings",
        icon: "far fa-clock",
        matches_routes: ["settingsAgenda"]
      },
      {
        name: "Patientèle",
        url: "/patients",
        icon: "fas fa-id-card-alt",
        matches_routes: ["dmp", "listPatients"]
      },
      {
        name: "Consultations",
        url: "/consultations",
        icon: "fas fa-stethoscope",
        matches_routes: ["consultations", "folders_detail"]
      },
      {
        name: "Réseau d’adressage",
        url: "/social-network",
        icon: "socialNetwork",
        matches_routes: ["socialNetwork"]
      },
      {
        name: "Chat",
        url: "/chat/pro",
        icon: "far fa-comments",
        matches_routes: ["chatDashboard"]
      },
      {
        name: "Messagerie Pro",
        url: "/messagre/pro",
        icon: "fas fa-at",
        matches_routes: ["messagesPro"]
      },
      // {
      //   name: "Facturation",
      //   url: "/invoicing",
      //   icon: "fas fa-receipt",
      //   matches_routes: ["invoicing"]
      // },
      // {
      //   name: "Réseaux sociaux",
      //   url: "/social-network",
      //   icon: "fas fa-bullhorn",
      //   class: "text-gray-600"
      // },
      {
        name: "Posologie",
        url: "/posologie",
        icon: "fas fa-flask"
      }
    ]
  },
  {
    name: "SECTION TWO",
    subs: [
      {
        name: "Utilisateurs",
        url: "/users",
        icon: "fas fa-user-lock",
        matches_routes: ["users"]
      },
      {
        name: "Paramètres",
        url: "/settings",
        icon: "fas fa-cog",
        matches_routes: ["settings"]
      },
      {
        name: "Support",
        url: "/support",
        icon: "fas fa-info-circle",
        matches_routes: ["page_support", "page_support_discussion"]
      },
      {
        name: "Faq",
        url: "https://help.dok.ma/fr/",
        type: "url",
        icon: "fas fa-question"
      }
    ]
  },

  {
    name: "SECTION THREE",
    subs: [
      {
        name: "Se déconnecter",
        function: () => {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userRole");
          localStorage.removeItem("accessToken");
          window.location.pathname = "/";
        },
        icon: "fas fa-sign-out-alt"
      }
    ]
  }
];

export default { items };
