<template>
  <div class="relative flex flex-col">
    <div
      class="
        text-black
        border-0 border-b border-solid border-gray-300
        px-8
        fixed
        z-40
        inset-x-0
        h-71
        flex
        items-center
        justify-between
        gap-x-8
        lg:gap-x-20
        bg-white
      "
    >
      <!-- EXPANED BUTTON IF PAGE CONSULTATION -->
      <button
        class="mr-3 block lg:hidden bg-transparent border-0 cursor-pointer"
        @click="onCallMobileSide"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-menu"
        >
          <line x1="3" y1="12" x2="21" y2="12"></line>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="3" y1="18" x2="21" y2="18"></line>
        </svg>
      </button>

      <!-- LOGO -->
      <img
        @click="logoClick"
        src="/logos/pro/pro.png"
        srcset="/logos/pro/pro@2x.png 2x, /logos/pro/pro@3x.png 3x"
        class="h-30 cursor-pointer"
        alt="dok pro"
      />

      <!-- ACTIONS -->
      <div class="flex-1 flex items-center justify-start gap-x-6">
        <!-- Boit Email -->
        <div
          class="flex flex-row gap-x-1 items-center text-lg bg-dokBlue-lighter p-2 rounded-lg shadow-blue"
          v-if="$route.name === 'messagesPro'"
        >
          <span class="font-EffraR text-dokBlue-ultra">
            Mon email pro :
            {{
              $auth.getUserInfo().slug &&
                `${$auth.getUserInfo().slug}@dokmaroc.com`
            }}
          </span>
        </div>

        <!-- NAVBAR IF PAGE CONSULTATION -->
        <nav
          class="hidden lg:flex flex-row gap-x-8 w-full"
          v-if="$route.name === 'folders_detail'"
        >
          <div
            v-for="(nv, index) in navConsultation"
            :key="index"
            @click="setPage(nv.id)"
            class="
              py-3
              text-base
              cursor-pointer
              flex
              gap-x-2
              items-center
              whitespace-no-wrap
              stroke-current
              border-0 border-b-2 border-solid
              relative
            "
            :class="
              currentConsultationPage === nv.id
                ? 'text-dokBlue-ultra font-EffraM  border-dokBlue-ultra'
                : 'text-navgray font-EffraR border-white'
            "
          >
            <img :src="nv.icon" />
            {{ nv.name }}
          </div>
        </nav>

        <!-- BUTTON SWITCH MESSANGER OR EMAIL-->
        <div
          class="hidden lg:flex gap-x-5"
          v-if="$route.name === 'messagesDashboard'"
        >
          <dok-button
            @click.native="messagetSet('messages')"
            custom-class="flex items-center gap-x-3 font-EffraM"
            size="md"
            aria-label="Messages"
          >
            <i class="far fa-comments"></i>
            Messages
          </dok-button>

          <dok-button
            @click.native="messagetSet('emails')"
            custom-class="flex items-center gap-x-3 font-EffraM"
            size="md"
            bg="rose"
            aria-label="Emails"
          >
            <i class="fas fa-inbox"></i>
            Emails
          </dok-button>
        </div>

        <!-- Button social Network Inviter-->
        <dok-button
          v-if="$route.name === 'socialNetwork'"
          @click.native="buttonInviterEmite"
          custom-class="hidden lg:flex items-center gap-x-1 font-EffraM px-8"
          size="md"
          aria-label="Inviter confrère"
        >
          <svg
            width="18"
            height="18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5 1.5 8.25 9.75M16.5 1.5l-5.25 15-3-6.75-6.75-3 15-5.25z"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Inviter confrère
        </dok-button>

        <!-- Button Create New Appointments -->
        <dok-button
          v-if="$route.name === 'consultations'"
          @click.native="createNewConsultation"
          custom-class="hidden lg:flex items-center gap-x-1 font-EffraM"
          size="md"
          aria-label="Ajouter horaire"
        >
          <img src="/svg/calendarWhite.svg" alt="Ajouter horaire" />
          Créer une nouvelle consultation sans rendez-vous
        </dok-button>

        <!-- Button Create New patient -->
        <dok-button
          v-if="$route.name === 'listPatients'"
          @click.native="createNewPatient"
          custom-class="hidden lg:flex items-center gap-x-1 font-EffraM"
          size="md"
          aria-label="Créer un nouveau patient"
        >
          <img src="/svg/user_plus_white.svg" alt="Créer un nouveau patient" />
          Créer un nouveau patient
        </dok-button>

        <!-- Button Create New Scheduplle -->
        <dok-button
          v-if="$route.name === 'settingsAgenda'"
          @click.native="createSchedule"
          custom-class="hidden lg:flex items-center gap-x-1 font-EffraM"
          size="md"
          aria-label="Ajouter horaire"
        >
          <img src="/svg/plus-white.svg" alt="Ajouter horaire" />
          Ajouter horaire
        </dok-button>

        <!-- Button Create Appointments -->
        <dok-button
          v-if="$route.name === 'agenda'"
          @click.native="createAppointment"
          custom-class="flex items-center gap-x-1 font-EffraM truncate"
          size="md"
          aria-label="Créer un rendez-vous"
        >
          <img
            class="hidden lg:block"
            src="/svg/calendarWhite.svg"
            alt="Créer un rendez-vous"
          />
          <span class="hidden lg:block">Créer un rendez-vous</span>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="calendar-plus"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            class="block lg:hidden"
            height="20"
            width="20"
          >
            <path
              fill="currentColor"
              d="M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm316 140c0-6.6-5.4-12-12-12h-60v-60c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v60h-60c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h60v60c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-60h60c6.6 0 12-5.4 12-12v-40z"
              class=""
            ></path>
          </svg>
        </dok-button>

        <!-- Button Today-->
        <button
          class="
            cursor-pointer
            bg-transparent
            border-2 border-solid
            font-EffraR
            text-lg
            rounded-cu
            px-5
            text-blacked
            h-10
            hidden
            lg:flex
            items-center
            justify-center
            truncate
          "
          style="border-color: #f1f1f1"
          @click="agendaToday"
          v-if="$route.name === 'agenda'"
          aria-label="Ajuourd'hui"
        >
          Aujourd’hui
        </button>

        <!-- Switch agenda type -->
        <div
          class="hidden lg:flex items-center switch-button"
          v-if="$route.name === 'agenda'"
        >
          <button
            class="cursor-pointer day"
            :class="type === 'day' ? 'active' : ''"
            @click="setView('day')"
            aria-label="Jour"
          >
            Jour
          </button>
          <button
            class="cursor-pointer week"
            :class="type === 'week' ? 'active' : ''"
            @click="setView('week')"
            aria-label="Semaine"
          >
            Semaine
          </button>
          <button
            class="cursor-pointer month"
            :class="type === 'month' ? 'active' : ''"
            @click="setView('month')"
            aria-label="Mois"
          >
            Mois
          </button>
        </div>

        <!-- Next & Previues Date agenda -->
        <div class="hidden xl:flex gap-x-1">
          <div
            v-if="$route.name === 'agenda'"
            id="nextPrev"
            class="flex items-center"
          >
            <button
              class="p-0 m-0 bg-transparent border-0 cursor-pointer"
              @click="toDate('previous')"
              aria-label="Former"
            >
              <img src="/svg/chevron-left.svg" alt="former" />
            </button>
            <button
              class="p-0 m-0 bg-transparent border-0 cursor-pointer"
              @click="toDate('next')"
              aria-label="Next one"
            >
              <img src="/svg/chevron-right.svg" alt="next one" />
            </button>
          </div>

          <!-- Date Picker -->
          <div
            v-if="$route.name === 'agenda'"
            id="Date Picker"
            class="text-xl font-EffraM flex items-center gap-x-2 relative"
          >
            <label for="choiceCurrentDate" class="capitalize truncate">{{
              _moment()($store.state.agenda.pickerDate).format("MMMM YYYY")
            }}</label>
            <img
              src="/svg/chevron-down.svg"
              class="cursor-pointer"
              alt="open"
            />
            <date-picker
              id="choiceCurrentDate"
              :clearable="false"
              format="DD-MM-YYYY"
              v-model="$store.state.agenda.pickerDate"
              class="absolute opacity-0 w-auto"
            />
          </div>
        </div>
      </div>

      <!-- RIGHT ICONS -->
      <div
        class="flex justify-end"
        :class="$route.name === 'folders_detail' ? 'flex-1 lg:flex-none' : ''"
      >
        <rightInfo />
      </div>
    </div>

    <!-- NAVBAR CONSULTATION MOBILE -->
    <div
      class="
        bg-white
        shadow-xl
        z-[99999999999999]
        mt-[72px]
        w-full
        fixed
        lg:hidden
      "
      v-if="navConsultationIs"
    >
      <div
        v-for="(nv, index) in navConsultation"
        :key="index"
        @click="setPage(nv.id)"
        class="
          py-3
          px-2
          text-base
          cursor-pointer
          flex
          gap-x-2
          items-center
          whitespace-no-wrap
          stroke-current
          border-0 border-b-2 border-solid
          relative
        "
        :class="
          currentConsultationPage === nv.id
            ? 'text-dokBlue-ultra font-EffraM  border-dokBlue-ultra'
            : 'text-navgray font-EffraR border-white'
        "
      >
        <img :src="nv.icon" />
        {{ nv.name }}
      </div>
    </div>
    <!-- CHANGE DATE EVENT -->
    <modal
      :isActive="eventPropos.modal"
      :exitModal="
        () => {
          eventPropos.modal = false;
          eventPropos.data = {};
        }
      "
      :title="`${eventPropos.data.patient_firstName} ${
        eventPropos.data.patient_lastName
      } at, ${_moment()(eventPropos.data.startAt).format('DD/MM/YYYY HH:mm')}`"
      :min-width="400"
    >
      <div class="px-2 py-4 lg:py-0">
        <div class="flex flex-col gap-y-2 mb-2">
          <label for="date" class="font-EffraR text-lg"
            >Choisissez une date :
          </label>
          <date-picker
            format="DD/MM/YYYY"
            v-model="proposDate"
            :lang="DatePickerLocal"
            class="w-full"
            @change="generateHours"
          >
            <span slot="icon-calendar"><i class="fas fa-search"></i></span>
          </date-picker>
        </div>

        <loader
          v-if="availableHoursLoader === 'LOADING'"
          class="flex justify-center w-full"
        >
        </loader>

        <template v-if="availableHoursLoader === false">
          <div
            class="grid gap-3 grid-cols-3 md:grid-cols-6 lg:grid-cols-8 mb-5"
          >
            <div
              v-for="(hour, index) in dataSlice"
              :key="index"
              class="
                text-center
                py-2
                rounded-cu
                border border-solid border-gray-300
                shadow
              "
              :class="
                hour.isAvailable
                  ? ` ${
                      _moment()(proposDate).format('HH:mm') ===
                      hour.formatedHour
                        ? 'bg-dokBlue-ultra text-white cursor-pointer'
                        : 'bg-white text-dokBlue-ultra cursor-pointer'
                    }
                `
                  : 'bg-gray-200 text-gray-600 cursor-not-allowed'
              "
              @click="setHour(hour.formatedHour)"
            >
              <span class="block font-EffraR"> {{ hour.formatedHour }}</span>
            </div>
          </div>

          <div class="flex justify-end">
            <dok-button
              bg="gray"
              size="md"
              :custom-class="`font-EffraM px-8 ${saveButton ? 'mb-2' : ''}`"
              @click.native="voirAllAvailabelHours = !voirAllAvailabelHours"
            >
              {{ !voirAllAvailabelHours ? "Voir plus" : "Voir moins" }}
            </dok-button>
          </div>
        </template>

        <!-- Save Action -->
        <button
          v-if="saveButton"
          @click="changeDate"
          class="
            bg-dokBlue-ultra
            h-12
            w-full
            text-white text-lg
            font-EffraM
            border-0
            rounded
            cursor-pointer
            flex
            items-center
            justify-center
            gap-x-2
          "
          aria-label="Enregistrer"
        >
          Enregistrer les modifications
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import { EventBus } from "@/event-bus";
import { mapActions } from "vuex";
import modal from "@/views/global-components/modal";
import rightInfo from "./notifications/index";
import loader from "@/views/global-components/loader";
import _ from "lodash";

// Date Picker
import DatePicker from "vue2-datepicker";
import { fr } from "vue2-datepicker/locale/fr";
import "vue2-datepicker/index.css";

export default {
  data() {
    return {
      id: this.$auth.getUserId(),
      proposDate: moment(new Date()).clone(),
      DatePickerLocal: fr,
      availableHours: [],
      availableHoursLoader: "STRING",
      saveButton: false,
      ButtonInviter: false,
      upComming: {
        countDown: null,
      },
      eventPropos: {
        modal: false,
        data: {},
      },
      mobileActive: this.$auth.getUserInfo().isMobileVerified,
      currentConsultationPage: 1,
      navConsultation: [
        {
          id: 1,
          name: "Patient",
          icon: "/svg/nav/user.svg",
        },
        {
          id: 2,
          name: "Consultation",
          icon: "/svg/nav/edit-2.svg",
        },
        {
          id: 3,
          name: "Ordonnances et certificats",
          icon: "/svg/nav/paperclip.svg",
        },
        {
          id: 4,
          name: "Compte rendu",
          icon: "/svg/nav/file-text.svg",
        },
        {
          id: 5,
          name: "Documents générés",
          icon: "/svg/nav/folder.svg",
        },
      ],
      navConsultationIs: false,
      voirAllAvailabelHours: false,
    };
  },

  mounted() {
    EventBus.$on("CONSULTATION_TO_PAGE_CHANGE_NAV", (value) => {
      this.currentConsultationPage = value;
    });

    EventBus.$on("INVITER_CONFRERE_BUTTON", (value) => {
      this.ButtonInviter = value;
    });

    EventBus.$on("ProposEvent", (event) => {
      this.eventPropos.modal = true;
      this.eventPropos.data = event;
    });

    // Listen For Owner Waiting Rooom
    EventBus.$on("isJoinToWaitingRoom", () => {
      this.upCommingTele();
    });

    EventBus.$on("changeStatusOnline", (payload) => {
      EventBus.$emit("flashDok", {
        type: "success",
        message: `votre patient ${payload.fullName} est entré dans la salle d'attente virtuelle`,
        timerAlert: 8000,
      });
      this.$runSoundNotification();
    });
  },
  components: { rightInfo, DatePicker, modal, loader },
  computed: {
    PickerDate: {
      get() {
        return this.$store.state.agenda.pickerDate;
      },
      set(value) {
        this.$store.commit("agenda/SET_DATE_PICKET", value);
      },
    },
    type: {
      get() {
        return this.$store.state.agenda.typeAgenda;
      },
      set(view) {
        this.$store.commit("agenda/SET_VIEW", view);
      },
    },
    dataSlice: {
      get() {
        return !this.voirAllAvailabelHours
          ? _.take(this.availableHours, 18)
          : this.availableHours;
      },
      set() {},
    },
  },

  methods: {
    ...mapActions("agenda", ["GENERATED_HOURS", "UPDATE_DATE_EVENT"]),
    async logoClick() {
      this.$performTracking({
        data: {
          id: this.id,
          route: "HOME",
          action: "GET",
          data: {},
        },
      });
      this.$router.push("/");
    },

    async setPage(num) {
      await EventBus.$emit("CONSULTATION_TO_PAGE", num);
      this.currentConsultationPage = num;
      this.$nextTick(() => {
        this.navConsultationIs = false;
      });
    },

    async upCommingTele() {
      this.$store.dispatch("agenda/GET_UPCOMING_RDV", {
        onData: ({ ok, data }) => {
          if (ok) {
            this.upComming = data;
            this.$forceUpdate();
          }
        },
      });
    },

    async changeDate() {
      const data = await this.UPDATE_DATE_EVENT({
        id: this.eventPropos.data._id,
        toDate: this.proposDate,
      });
      if (data.ok) {
        EventBus.$emit("Propos_Date_Event", {
          id: this.eventPropos.data._id,
          date: this.proposDate,
        });
        this.eventPropos = {
          modal: false,
          data: {},
        };
        this.availableHours = [];
        this.availableHoursLoader = "STRING";
        this.saveButton = false;
      }
    },

    async generateHours() {
      if (
        this.eventPropos.data.categoryId === null ||
        !moment(this.proposDate).isValid()
      ) {
        return;
      }
      this.availableHoursLoader = "LOADING";
      const { data, ok } = await this.GENERATED_HOURS({
        date: moment(this.proposDate).format("DD/MM/YYYY"),
        category: this.eventPropos.data.categoryId,
      });
      if (ok) {
        this.availableHours = data;
        this.availableHoursLoader = false;
      }
    },

    messagetSet(type) {
      EventBus.$emit("CHNAGE_INBOX_TO", type);
    },

    async createNewPatient() {
      this.$performTracking({
        data: {
          id: this.id,
          route: "PATIENTEL",
          action: "NEW",
          data: {},
        },
      });
      EventBus.$emit("Create_New_Patient");
    },

    async createNewConsultation() {
      this.$performTracking({
        data: {
          id: this.id,
          route: "CONSULTATION",
          action: "NEW",
          data: {},
        },
      });
      EventBus.$emit("Create_New_Consultation");
    },

    async createSchedule() {
      this.$performTracking({
        data: {
          id: this.id,
          route: "HORAIRE",
          action: "NEW",
          data: {},
        },
      });
      EventBus.$emit("Create_Schedule");
    },

    agendaToday() {
      EventBus.$emit("onTodayClick");
    },

    setView(type) {
      if (type === "day" || type === "week" || type === "month") {
        this.type = type;
      }
    },

    async createAppointment() {
      this.$performTracking({
        data: {
          id: this.id,
          route: "APPOINTEMENT",
          action: "NEW",
          data: {},
        },
      });
      EventBus.$emit("Modal_Create_Appointment", moment());
    },

    async buttonInviterEmite() {
      this.$performTracking({
        data: {
          id: this.id,
          route: "RESEAUX_ADRESSAGE",
          action: "NEW",
          data: {},
        },
      });
      EventBus.$emit("MODAL_INVITER_CONFRERE");
    },

    toDate(type) {
      EventBus.$emit("TypeToDate", type);
    },

    transform(props) {
      if (props.minutes < 1) {
        this.upComming.nowIs = "start";
        this.$forceUpdate();
      }

      Object.entries(props).forEach(([key, value]) => {
        const digits = value < 10 ? `0${value}` : value;
        const word = value < 2 ? key.replace(/s$/, "") : key;
        props[key] = `${digits} ${word}`;
      });

      return props;
    },

    onCallSidebar() {
      EventBus.$emit("Call-Side-Bar");
    },

    closeUpComming() {
      this.$confirm({
        message: `Êtes-vous sûr(e)?`,
        button: {
          no: "Non",
          yes: "Oui",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.upComming = {};
          }
        },
      });
    },

    setHour(hour) {
      let formHour = moment(hour, "HH:mm");
      this.proposDate = moment(this.proposDate)
        .set("minute", formHour.format("mm"))
        .set("hour", formHour.format("HH"));

      if (!this.proposDate.isValid()) {
        return (this.saveButton = false);
      }
      return (this.saveButton = true);
    },

    onCallMobileSide() {
      EventBus.$emit("CALL_MOBILE_SIDEBAR_DOK_PRO");
    },

    _moment() {
      return moment;
    },
  },
};
</script>

<style lang="scss">
.switch-button {
  button {
    @apply bg-transparent font-EffraR text-lg px-5 text-blacked h-10 flex items-center justify-center;
  }

  .day {
    @apply border-0 border-solid border-t-2 border-b-2 border-l-2 rounded-l-cu;
    border-color: #f1f1f1;
  }
  .week {
    @apply border-0 border-solid border-t-2 border-b-2 border-l-2 border-r-2;
    border-color: #f1f1f1;
  }
  .month {
    @apply border-0 border-solid border-t-2 border-b-2 border-r-2 rounded-r-cu;
    border-color: #f1f1f1;
  }

  .active {
    @apply bg-dokBlue-lighter border-dokBlue-ultra border-2 rounded-cu text-dokBlue-ultra;
  }
}
</style>
