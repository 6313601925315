<template>
  <div>
    <div
      class="bg-black bg-opacity-40 fixed lg:hidden z-500 inset-0"
      v-if="showMobile"
      @click="
        () => {
          showMobile = false;
        }
      "
    ></div>
    <div id="sidebar" class="group">
      <div
        class="
          bg-sidebar
          group-hover:w-210
          group-hover:fixed
          group-hover:z-500
          group-hover:shadow-sm
          fixed
          lg:static
          h-screen
          z-1000
          pt-71
          overflow-hidden
        "
        :class="showMobile ? 'w-50 lg:w-50' : 'w-none lg:w-50'"
      >
        <div class="flex flex-col gap-y-12 py-5 px-0">
          <div v-for="(catLinks, indexCat) in links" :key="indexCat">
            <!-- Subs Link -->
            <ul v-if="catLinks.subs.length" class="flex flex-col">
              <li
                v-for="(subLinks, indexSub) in catLinks.subs"
                :key="indexSub"
                class="
                  flex
                  items-center
                  justify-center
                  group-hover:justify-start group-hover:px-2
                  hover:bg-dokBlue-lighter
                "
                :class="
                  subLinks.matches_routes
                    ? subLinks.matches_routes.includes($route.name)
                      ? 'bg-dokBlue-lighter border-0 border-l-2 border-solid border-dokBlue-ultra'
                      : ''
                    : false
                "
                style="height: 48px"
              >
                <template v-if="subLinks.function">
                  <div
                    class="flex items-center justify-center text-blackedsidebar"
                    style="width: 20px; height: 20px"
                  >
                    <i class="text-xl" :class="subLinks.icon"></i>
                  </div>
                  <div
                    class="
                      text-lg
                      hidden
                      group-hover:block
                      px-2
                      text-blackedsidebar
                      font-EffraR
                      cursor-pointer
                    "
                    @click="
                      $performTracking({
                        data: {
                          id: id,
                          route: 'LOGOUT',
                          action: 'GET',
                          data: {},
                        },
                      });
                      subLinks.function();
                    "
                  >
                    {{ subLinks.name }}
                  </div>
                </template>
                <template v-if="subLinks.url">
                  <div
                    class="flex items-center justify-center"
                    :class="
                      $route.path === subLinks.url
                        ? 'text-dokBlue-ultra'
                        : 'text-blackedsidebar'
                    "
                    style="width: 20px; height: 20px"
                  >
                    <i
                      class="text-xl"
                      :class="subLinks.icon"
                      v-if="subLinks.icon !== 'socialNetwork'"
                    ></i>
                    <svg
                      width="20"
                      height="20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      v-if="subLinks.icon === 'socialNetwork'"
                    >
                      <path
                        d="M19.595 7.865c0-1.35-.742-2.529-1.84-3.151a2.31 2.31 0 0 0 .541-1.487A2.324 2.324 0 0 0 15.975.905a2.324 2.324 0 0 0-2.322 2.322c0 .565.204 1.084.541 1.487a3.623 3.623 0 0 0-1.84 3.151V8.98c0 .36.292.651.652.651h5.937c.36 0 .652-.292.652-.651V7.865zm-3.62-5.657a1.02 1.02 0 0 1 1.018 1.019 1.02 1.02 0 0 1-1.018 1.018 1.02 1.02 0 0 1-1.019-1.018 1.02 1.02 0 0 1 1.019-1.019zm-2.317 6.119v-.462a2.32 2.32 0 0 1 2.317-2.317 2.32 2.32 0 0 1 2.317 2.317v.462h-4.635zm-6.012.652V7.865c0-1.35-.743-2.529-1.84-3.151a2.31 2.31 0 0 0 .54-1.487A2.324 2.324 0 0 0 4.027.905a2.324 2.324 0 0 0-2.322 2.322c0 .565.204 1.084.54 1.487A3.623 3.623 0 0 0 .406 7.865V8.98c0 .36.292.651.652.651h5.937c.36 0 .652-.292.652-.651zm-3.62-6.77a1.02 1.02 0 0 1 1.018 1.018 1.02 1.02 0 0 1-1.019 1.018 1.02 1.02 0 0 1-1.018-1.018 1.02 1.02 0 0 1 1.018-1.019zM1.707 8.326v-.462a2.32 2.32 0 0 1 2.317-2.317 2.32 2.32 0 0 1 2.317 2.317v.462H1.708zm14.727 2.788a.652.652 0 0 0-.921 0l-2.227 2.227a.652.652 0 1 0 .922.921l1.114-1.114v3.713H4.677v-5.286a.652.652 0 1 0-1.303 0v5.938c0 .36.292.651.651.651h11.95c.36 0 .651-.291.651-.651v-4.365l1.114 1.115a.652.652 0 0 0 .922-.922l-2.227-2.227z"
                        fill="CurrentColor"
                        stroke="CurrentColor"
                        stroke-width=".19"
                      />
                    </svg>
                  </div>

                  <a
                    v-if="!subLinks.type"
                    @click="routeClick(subLinks.url)"
                    href="javascript:void(0);"
                    class="text-lg hidden group-hover:block px-2"
                    :class="
                      $route.path === subLinks.url
                        ? 'text-dokBlue-ultra font-EffraM'
                        : 'text-blackedsidebar font-EffraR'
                    "
                  >
                    {{ subLinks.name }}
                  </a>
                  <a
                    v-if="subLinks.type && subLinks.type === 'url'"
                    @click="routeClickType(subLinks.url)"
                    href="javascript:void(0);"
                    class="
                      text-lg
                      hidden
                      group-hover:block
                      px-2
                      text-blackedsidebar
                      font-EffraR
                    "
                  >
                    {{ subLinks.name }}
                  </a>
                </template>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import links from "./items";

export default {
  data() {
    return {
      links: [],
      showMobile: false,
      id: this.$auth.getUserId(),
    };
  },

  mounted() {
    EventBus.$on("CALL_MOBILE_SIDEBAR_DOK_PRO", () => {
      this.showMobile = !this.showMobile;
    });

    // console.log(this.showMobile ? "w-50 lg:w-50" : "w-none lg:w-50");
  },

  async created() {
    let user = await this.$auth.getUserInfo();
    if (user.role == "TEAM-MEMBER") {
      let items = links.items.map((item) => {
        if (item.subs && item.name != "SECTION THREE") {
          item.subs = item.subs.filter((i) => {
            return i.name == "Paramètres" || user.roles.indexOf(i.name) !== -1;
          });
        }
        return item;
      });
      this.links = items;
    } else {
      this.links = links.items;
    }
  },

  methods: {
    async routeClickType(url) {
      this.$performTracking({
        data: {
          id: this.id,
          route: "FAQ",
          action: "GET",
          data: {},
        },
      });
      window.open(url, "_blank");
    },

    async routeClick(url) {
      let route = "HOME";
      switch (url) {
        case "/agenda":
          route = "AGENDA";
          break;
        case "/agenda/settings":
          route = "AGENDA_SETTINGS";
          break;
        case "/patients":
          route = "PATIENTEL";
          break;
        case "/consultations":
          route = "CONSULTATION";
          break;
        case "/social-network":
          route = "RESEAUX_ADRESSAGE";
          break;
        case "/chat/pro":
          route = "CHAT";
          break;
        case "/messagre/pro":
          route = "MESSAGERIE";
          break;
        case "/users":
          route = "USERS";
          break;
        case "/settings":
          route = "SETTINGS";
          break;
        case "/support":
          route = "SUPPORT";
          break;
        case "https://help.dok.ma/fr/":
          route = "FAQ";
          break;
        default:
          route = "AGENDA";
          break;
      }
      this.$performTracking({
        data: {
          id: this.id,
          route: route,
          action: "GET",
          data: {},
        },
      });
      this.$router.push(url);
    },
  },
};
</script>
