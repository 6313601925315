<template>
  <div class="flex items-center gap-x-4">
    <!-- Upcomming -->
    <teleconsulation v-if="upcomming.length" :data="upcomming" />
    <!-- End Upcomming -->
    <mobileVerfied v-show="!$auth.getUserInfo().isMobileVerified" />
    <!-- Appointments notifications -->
    <appointments />
    <!-- global notifications -->
    <notifications />
    <!-- dropdown user -->
    <user-info />
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
const appointments = () => import("./notificationsAppointments");
const notifications = () => import("./notificationsGlobal");
const userInfo = () => import("./me");
const mobileVerfied = () => import("./mobileVerification");
const teleconsulation = () => import("./upcomming");

export default {
  components: {
    appointments,
    notifications,
    userInfo,
    mobileVerfied,
    teleconsulation
  },
  data() {
    return {
      upcomming: []
    };
  },
  mounted() {
    // Listen For Owner Waiting Rooom
    EventBus.$on("isJoinToWaitingRoom", payload => this.onTeleconsultation());

    // Listen For Reload reload_nav_bar
    EventBus.$on("reload_nav_bar", () => {
      this.$forceUpdate();
    });

    // Listen For Change Visiblite Up Comming
    EventBus.$on("CHNAGE_VISIBLITY", this.onTeleconsultation);
  },
  created() {
    this.onTeleconsultation(); // Sent request Teleconseltation
  },
  methods: {
    async onTeleconsultation() {
      this.$store.dispatch("agenda/GET_UPCOMING_RDV", {
        onData: ({ ok, data }) => {
          //console.log(data);
          if (ok) {
            this.upcomming = data;
          }
        }
      });
    }
  }
};
</script>

<style></style>
